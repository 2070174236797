@font-face {
  font-family: "open-sans";
  src: local("open-sans"),
    url(../assets/fonts/muzeum/open-sans.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "open-sans-bold";
  src: local("open-sans-bold"),
    url(../assets/fonts/muzeum/open-sans-bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "segoe";
  src: local("segoe"), url(../assets/fonts/cc/Segoe-UI.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "segoe-bold";
  src: local("segoe-bold"),
    url(../assets/fonts/cc/SegoeUIBlack.ttf) format("truetype");
  font-display: swap;
}

body iframe {
  display: none !important;
  z-index: 0 !important;
}

.wallet {
  // width: 100vw;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100%;
  min-height: calc(100vh - 40px);
  position: relative;
  // margin-bottom: 100px;
  // padding-bottom: 20px;
}
body .single-card .slick-arrow {
  top: 42vh !important;
}
.carousel-btn-holder {
  z-index: 999999999;
  display: flex;
  gap: 25px;
}
.tricky-carousel-button {
  border-radius: 50px;
}
.tricky-carousel-button:hover {
  cursor: pointer;
}
.tricky-carousel-button img {
  max-height: 40px !important;
}
// .tricky-next:before {
//   content: '▶';
//   color: #fff;
// }
// .tricky-prev:before {
//   content: '../assets/images/left.png';
//   color: #fff;
// }
.new-card-title {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 36px;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 800px;
  h1 {
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Set the maximum number of lines */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}

.new-card-other-infos-btn {
  background-color: transparent;
  border: none;
  font-family: "open-sans-bold";
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 20px;
  padding: 0;
  cursor: pointer;
  img {
    padding-left: 10px;
  }
}
.new-card-bordered-btn {
  background-color: transparent;
  font-family: "open-sans-bold";
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 18px;
  padding: 20px;
  cursor: pointer;
}
.crest-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.new-card-container {
  p,
  li {
    font-family: "open-sans";
  }
  li {
    padding-bottom: 5px;
  }
  ul {
    list-style: none;
    padding-left: 40px;
  }
  span {
    font-family: "open-sans-bold";
  }
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.card-text-normal {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}
.owner-warning {
  color: #ff5300;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "open-sans-semibold" !important;
}
.card-container-outer {
  margin: 0 20px;
}
@media (max-width: 575px) {
  .card-container-outer {
    margin: 0;
  }
}
.new-card-container {
  .wallet-container {
    align-items: start;
  }
}
.new-card-container {
  .new-crest-container {
    align-items: center;
  }
}
.new-crest-container {
  max-width: 1062px !important;
}
.new-crest-container {
  .back-to-cards {
    align-self: flex-start;
  }
}
.current-card-image {
  cursor: pointer;
}

.new-card-modal {
  position: fixed;
  top: 0;
  z-index: 32412342314;
  background-color: #000000cb;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 95%;
    max-height: 90vh;
  }
  .crest-modal-view {
    width: 90%;
    min-width: 300px;
    min-height: 300px;
    max-height: 90%;
    align-items: center;
    justify-content: center;
    // max-width: 100%;
    // max-height: 90vh;
    display: flex;
  }
}
.back-to-cards {
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  color: #938d81;
  font-size: 14px;
  font-family: "open-sans-semibold";
  cursor: pointer;
}
@media (max-width: 992px) {
  .wallet-container {
    padding-bottom: 10px !important;
  }
}
.wallet-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding-bottom: 130px;
  .card-holder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    .cards {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;
      position: relative;

      .no-cards {
        font-size: 15px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        font-family: "segoe";
        margin-bottom: 150px;
      }
    }
  }
  .card-container {
    width: 100%;
    max-width: 1062px;
    display: flex;
    gap: 5px;
  }
  .nav-outer-container {
    display: flex;
    width: 100%;
  }
  .nav-container {
    flex-basis: 50%;
    width: 100%;
    max-width: 840px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    p {
      font-family: "open-sans-semibold";
    }
  }
  .nav-button {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0;
    cursor: pointer;
    img {
      max-width: 32px;
    }
  }
  .nav-button-mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    display: none;
  }
  .nav-button-mobile-left {
    left: 0;
  }
  .nav-button-mobile-right {
    right: 0;
  }
  .card-side {
    position: relative;
    flex-basis: 50%;
  }
  .text-side {
    flex-basis: 50%;
    display: flex;
    align-items: center;
  }
  .card {
    position: relative;
    width: calc((100% - (30px * 3)) / 4);
    margin: 0;
    background: #fff;

    box-shadow: 0px 4px 6px 0px rgba(16, 24, 40, 0.03),
      0px 12px 16px 0px rgba(16, 24, 40, 0.08);
    cursor: pointer;
    .card-image {
      width: 100%;
      margin: 0;
    }

    .gift {
      position: absolute;
      top: 95px;
      left: 60px;
      z-index: 2;
      width: 70px;
    }
    .coin {
      position: absolute;
      width: 200px;
      height: 280px;
      border-radius: 4px;
    }
    .card-number {
      background-color: black;
      color: white;
      position: absolute;
      top: 10px;
      left: 10px;
      width: 20px;
      height: 20px;
      z-index: 1;
    }
  }
  .card img {
    max-width: 100%;
  }

  @media (max-width: 1400px) {
    margin: 0 auto;
    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;
      width: calc(100vw - 120px);
    }
  }

  @media (max-width: 1200px) {
    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;
      width: calc(100vw - 60px);
    }

    .card {
      width: calc((100% - (30px * 2)) / 3);
    }
  }

  @media (max-width: 992px) {
    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      .cards {
        padding: 0 30px;
      }
    }

    // .card {
    //   width: calc((100% - 30px) / 2);
    // }
  }

  @media (max-width: 768px) {
    .card-container-outer {
      margin: 0;
    }
    .card {
      width: calc((100% - 30px) / 2);
    }
    .card-container {
      flex-wrap: wrap;
    }
    .text-side {
      ul {
        padding: 0;
      }
    }

    .card-side,
    .text-side {
      flex-basis: 100%;
    }
    .nav-button-mobile {
      display: flex;
    }
    .nav-container {
      display: none;
    }
    .crest-buttons-container {
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 575px) {
    max-width: 90%;
    margin: 0 auto;

    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;

      .cards {
        padding: 0;
        margin-bottom: 0;
      }
    }
    .card {
      width: 100%;
      padding: 0;
    }
    .card-image {
      width: 100%;
    }
  }
}

.background-img {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.badge-image {
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.nav-back {
  // z-index: 10;
  // position: absolute;
  width: 100px;
  height: 25px;
  // top: 35px;
  // left: 100px;
  // cursor: pointer;
}

.logo-holder {
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.logo-div {
  margin-left: 0px;
}

.logo {
  width: 300px;
}

.address {
  text-align: center;
  font-family: "segoe";
  margin-bottom: 150px;
}

.address-title {
  font-size: 30px;
  line-height: 25px;
  color: white;
  margin: 10px;
}

.address .wallet-address {
  //font-family: "open-sans";
  font-style: normal;
  // font-weight: 600;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  //color: #5a513f;
  margin: auto 20px;

  margin-top: 8px;
  margin-bottom: 16px;
}

.modal {
  position: sticky;
  width: 100vw;
  z-index: 10;
  bottom: 0px;
  .modal-overlay {
    opacity: 1;
    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #65532f;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      height: 266px;
      // height: 306px;
      color: #fdf7f7;
      div {
        width: fit-content;
        text-align: center;
      }
      .bonus-title {
        font-size: 28px;
        line-height: 25px;
        margin-top: 37px;
        margin-bottom: 45px;
      }
      .bonus {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 50px;
      }
      .bonus-detail {
        font-size: 18px;
        line-height: 22px;
      }
      .modal-close {
        position: absolute;
        width: 30px;
        top: 30px;
        right: 28px;
      }
    }
  }
}

.grand-gift {
  width: 150px;
  height: 60px;
}

.modal-gift {
  width: 200px;
  position: absolute;
  top: 10px;
  left: 90px;
  z-index: 2;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 200px);
  max-width: 1320px;
  margin: 0 auto;
}
.header {
  .header-right-side {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .button-group-box {
    display: flex;
    gap: 16px;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}

.crest-button {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  // width: 604px;
  width: 100%;
  max-width: 368px;
  height: 42px;
  background: #ba5b2d;
  border: 1px solid #ba5b2d;
  border-radius: 40px;
  margin-bottom: 30px;

  font-family: "open-sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.crest-arrow {
  position: absolute;
  right: 15px;
}

.crest-text {
  margin: 0;
  margin-left: -10px;
}

.crest-shield {
  margin-top: 9px;
}

@media (min-width: 1441px) {
  .wallet .header {
    margin: 24px auto;
    margin: 24px auto;
    max-width: 1320px;
  }
}

@media (max-width: 1416px) {
  .wallet .wallet-container {
    // max-width: 1304px;
    margin: 0 auto;
  }
}

@media (max-width: 430px) {
  .wallet-container .card-holder {
    max-width: 100%;
  }

  .wallet-container .card .coin {
    top: 35px;
    left: 10px;
    width: 76px;
    height: 73px;
  }
  .wallet-container .card .gift {
    top: 45px;
    left: 25px;
    width: 45px;
  }
  .logo {
    width: 172px;
  }

  .wallet-container .address .address-title {
    font-size: 22px;
    margin: 5px;
  }

  .modal .modal-overlay .modal-content div {
    width: 246px;
  }
  .modal .modal-overlay .modal-content .bonus-title {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .modal .modal-overlay .modal-content .bonus {
    font-size: 16px;
    margin-bottom: 22px;
  }
  .modal .modal-overlay .modal-content .bonus-detail {
    font-size: 14px;
  }
  .modal-content .modal-close {
    width: 24px;
    top: 38px;
    right: 28px;
  }
}
.mobile-tabs {
  select {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    padding: 6px 13.17px;
    white-space: nowrap;
    font-family: "open-sans-semibold";
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: left;
    border-radius: 5px;
    background: url(../assets//images/arrow.png) no-repeat right center; /* Replace with your custom arrow icon */
    background-size: 12px 7px;
    background-position: right 10px center;
    position: relative;
    appearance: none; /* Disable default styling for other browsers */
    -webkit-appearance: none;
  }
  select:focus-visible {
    outline: none;
  }
  select option {
    background-color: rgba(90, 81, 63, 0.08);
    border: none;
  }
  width: 100%;
  display: none;
}
@media (max-width: 768px) {
  .owner-warning {
    justify-content: center;
  }
  .tab {
    display: none;
  }
  .mobile-tabs {
    display: block;
  }
  .crest-container-outer {
    width: unset !important;
    margin: 0 20px;
  }
  .new-card-title {
    align-self: center;
  }
  .new-card-title {
    h1 {
      font-size: 20px;
      line-height: 26px;
      text-align: center;
    }
  }
  .back-to-cards {
    align-self: flex-start;
  }
  .card-container .card-side {
    padding: 0 60px;
  }
  .logo {
    width: 200px;
  }
  .nav-back {
    width: 50px;
    height: 15px;
  }
  .grand-gift {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 375px) {
  .header-container {
    width: calc(100% - 40px);
  }
}

.tabs-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
  margin-bottom: 24px;
  background-color: rgb(90 81 63 / 8%);
  //background: rgba(255, 255, 255, 0.08);
  cursor: pointer;
  padding: 2px;
  .tab {
    color: #999999;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    padding: 6px 13.17px;
    white-space: nowrap;
    //font-family: "open-sans";
    //background-color: rgba(186, 91, 45, 0.1);
    font-family: "segoe-bold";
  }
  .selected-tab {
    background-color: rgba(186, 91, 45, 0.1);
    // color: #ba5b2d;
    //background: rgba(0, 213, 255, 0.1);
    //color: #00d5ff;
    color: #ba5b2d;
  }
}

@media (max-width: 576px) {
  .tabs-holder {
    width: 90%;
    .tab {
      padding: 7px 36px;
    }
  }
}

@media (max-width: 575px) {
  .tabs-holder {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .owner-warning {
    font-size: 12px;
  }
  .tabs-holder .tab {
    font-size: 12px !important;
    padding: 6px 8px !important;
  }
  .logo {
    width: 172px;
  }
  .nav-back {
    width: 20px;
    height: 15px;
    margin-top: 20px;
  }
  .header-container {
    width: calc(100% - 80px);
  }
}
@media (max-width: 330px) {
  .owner-warning {
    font-size: 10px;
  }
}
@media (max-width: 320px) {
  .tabs-holder .tab {
    padding: 6px;
  }
}

.wallet-title {
  margin-bottom: 32px;
  text-align: center;
}

.wallet-title h1 {
  // font-family: "open-sans-bold";
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.wallet-title p {
  margin: 8px 20px;
  // font-weight: 600;
  font-weight: 400;
  margin-bottom: 0;
  font-family: "segoe";

  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.card {
  margin: 8px;
  margin-bottom: 30px;
}
.card-content-holder {
  height: 70px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 18px;
  // @media screen and (max-width: 575px) {
  //   height: 72px;
  // }
}
.minted-supply-box {
  font-size: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid rgba(90, 81, 63, 0.2);
  color: #5a513f;
  width: fit-content;
}
.new-crest-container {
  .new-card-title {
    align-self: flex-start;
  }
}

.card-title {
  // width: 159px;
  //height: 40px;
  // font-family: "open-sans-bold";
  // text-align: center;
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0;
  overflow-wrap: break-word;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 800px) {
    text-align: left;
  }
}

.wallet-scanner-button {
  //position: sticky;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  // background: #ffffff;
  // box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);
  background: #00d5ff;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);

  //text
  // font-family: "open-sans";
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  //color: #ba5b2d;
}

.sticky-button-holder {
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.address-link {
  //color: #5a513f;
  font-family: "open-sans";
  font-style: normal;
  font-size: 12px;
}

.cardpage-title {
  // font-family: "open-sans";
  font-family: "segoe-bold";
  font-size: 20px;
  font-weight: 800;
  line-height: 32px;
  padding: 0 42px;
  letter-spacing: 0em;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.single-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  font-size: 30px;
  line-height: 22px;
  .title-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 24px;
    position: relative;
    width: 100%;
    gap: 16px;
    .close-btn {
      position: absolute;
      right: 22px;
      top: 6px;
      background-color: transparent;
      border: none;
      z-index: 10;
      width: 24px;
      height: 24px;
    }
    h1 {
      margin: 0;
      // -webkit-text-stroke: 1px white;
    }
  }
  .crest-fullscreen-container {
    width: 70%;
    height: auto;
  }
  .card-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1320px;
    margin: 0 auto;
    img {
      // height: 335px;
      // width: 335px;
      // margin-bottom: 16px;
      width: 90%;
      margin-bottom: 16px;
      max-width: 600px;
      max-height: 600px;
    }
    .cardpage-desc {
      font-family: "segoe";
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: white;
      margin: 0 20px;
      // margin-bottom: 150px;
      white-space: pre-line;
      max-width: 616px;
      margin-top: 30px;
      a {
        //color: #00d5ff;
        color: #d1c3bc;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1320px) {
    .card-description {
      //max-width: 400px;
      // width: 95%;
      width: 100%;
      img {
        width: 90%;
      }
      .cardpage-desc {
        max-width: 100%;
      }
    }
    .arrows-holder {
      .arrow {
        width: 20px;
        height: 20px;
      }
    }

    .notOwned {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 6px;
      // margin-bottom: 17px;
      // position: absolute;
      // top: 65px;
      // left: 75px;
      .notOwned-text {
        font-family: "open-sans";
        font-size: 12px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #ff5300;
        text-transform: uppercase;
        margin: 0;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .notOwned {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6px;
    // margin-bottom: 17px;
    // position: absolute;
    top: 65px;
    left: 44%;
    margin-bottom: 15px;
    .notOwned-text {
      font-family: "open-sans";
      font-size: 12px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #ff5300;
      text-transform: uppercase;
      margin: 0;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1918px) {
    .notOwned {
      left: 41.5%;
    }
  }

  @media (max-width: 1536px) {
    .notOwned {
      left: 40%;
    }
  }

  @media (max-width: 1440px) {
    .notOwned {
      left: 40%;
    }
  }

  @media (max-width: 1024px) {
    .notOwned {
      left: 35%;
    }
    // .single-card .card-description .cardpage-desc {
    //   color: '#000';
    // }
  }

  @media (max-width: 768px) {
    .notOwned {
      left: 31%;
    }
  }

  @media (max-width: 500px) {
    .notOwned {
      left: 21%;
    }
  }

  @media (max-width: 425px) {
    .notOwned {
      left: 16%;
    }

    // .card {
    //   width: 100%;
    // }
  }

  @media (max-width: 375px) {
    .close-btn {
      right: 10px !important;
    }
    .notOwned {
      top: 65px;
      left: 11%;
    }
  }

  .single-card .close-btn:hover {
    cursor: pointer;
  }

  .loader {
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    position: absolute;
    right: calc(50% - 50px);
  }

  @media (max-width: 425px) {
    .loader {
      left: 38%;
    }
  }

  .arrows-holder {
    display: flex;
    flex-direction: row;
    gap: 12px;
    .arrow {
      width: 20px;
      height: 20px;
    }
  }

  .slick-prev {
    left: 48% !important;
    top: 54% !important;
    z-index: 1000 !important;
  }
  .slick-next {
    right: 48% !important;
    top: 54% !important;
    z-index: 1000 !important;
  }

  @media (max-width: 1918px) {
    .slick-prev {
      left: 47.5% !important;
      top: 54% !important;
    }
    .slick-next {
      right: 47.5% !important;
      top: 54% !important;
    }
  }

  @media (max-width: 1536px) {
    .slick-prev {
      left: 47.5% !important;
      top: 56% !important;
    }
    .slick-next {
      right: 47.5% !important;
      top: 56% !important;
    }
  }

  @media (max-width: 1024px) {
    .slick-prev {
      left: 47.5% !important;
      top: 54% !important;
    }
    .slick-next {
      right: 47.5% !important;
      top: 54% !important;
    }
  }

  @media (max-width: 768px) {
    .slick-prev {
      left: 47% !important;
      top: 54% !important;
    }
    .slick-next {
      right: 47% !important;
      top: 54% !important;
    }
  }

  @media (max-width: 576px) {
    .slick-prev {
      left: 45.5% !important;
      top: 49% !important;
    }
    .slick-next {
      right: 45.5% !important;
      top: 49% !important;
    }
  }

  @media (max-width: 425px) {
    .slick-prev {
      left: 44% !important;
      top: 41.5% !important;
    }
    .slick-next {
      right: 44% !important;
      top: 41.5% !important;
    }
  }

  @media (max-width: 375px) {
    .slick-prev {
      left: 44% !important;
      top: 41.5% !important;
    }
    .slick-next {
      right: 44% !important;
      top: 41.5% !important;
    }
  }

  .slick-slider.slick-initialized {
    width: 100%;
  }
  .slick-arrow.slick-prev {
    background: #5a513f;
  }
  .slick-arrow.slick-next {
    background: #5a513f;
  }
}

.notification {
  width: 711px;
  height: 72px;
  background: #f8efea;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  margin-bottom: 34px;
}

.notif-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.notification-text {
  font-family: "open-sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #5a513f;
  margin: 0;
  // margin-bottom: 16px;
}

.notification-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  gap: 10px;
  width: 117px;
  // width: 290px;
  height: 40px;
  background: #5a513f;
  border: 1px solid #5a513f;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .notification {
    width: 700px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .notif-holder {
    padding: 0;
  }

  .notification-text {
    width: 500px;
    text-align: left;
  }
}
@media (max-width: 1024px) {
  .crest-container-outer {
    width: unset !important;
  }
}
@media (max-width: 767px) {
  .new-crest-container {
    .new-card-title {
      align-self: center;
    }
  }
  .notif-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    padding: 16px;
  }

  .notification-text {
    width: unset;
    margin-bottom: 16px;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .notification {
    width: 515px;
    height: 136px;
  }

  .notification-button {
    width: 100%;
  }

  // .notif-holder {
  //   padding: 0;
  // }

  // .notification-text {
  //   width: 500px;
  //   text-align: left;
  // }
}

@media (max-width: 500px) {
  .notification {
    width: 420px;
    height: 136px;
  }

  .notification-button {
    width: 350px;
    height: 40px;
  }
}

@media (max-width: 425px) {
  .notification {
    width: 380px;
    height: 136px;
  }

  .notification-button {
    width: 290px;
    height: 40px;
  }
}

@media (max-width: 375px) {
  .notification {
    width: 330px;
    height: 136px;
  }

  .notification-button {
    width: 290px;
    height: 40px;
  }
}

.card svg {
  border: 2px solid #ddd;
}

.wallet-button-bottom-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--white, #fff);
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.15);
  .button-group-box {
    display: none;
    @media screen and (max-width: 992px) {
      display: flex;
      gap: 15px 20px;
      flex-wrap: wrap;
      padding: 12px 30px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
    @media screen and (max-width: 575px) {
      max-width: 90%;
      padding: 12px 0;
      margin: auto;
    }
  }
}
// .link-style {
//   padding: 60px;
// }
