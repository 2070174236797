.purchase-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 32412342314;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}